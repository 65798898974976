import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Home.css';

export const Home = () => {
    const [mediaMatchesXs, setMediaMatchesXs] = useState(window.matchMedia("(min-width: 512px)").matches);

    useEffect(() => {
        window.matchMedia("(min-width: 512px)").addEventListener('change', (e) => setMediaMatchesXs(e.matches), true);
    }, []);

    return (
        <>
            <div className="home-page">
                <div>
                    {!mediaMatchesXs &&
                        <div className="text-center">
                            <div className="main-motivator mt-5 mb-3">Handicap Down!</div>
                            <div className="main-subtitle mb-3">
                                {/* <h2>Manage your Score Cards</h2>
                                <h2>Track your evolution</h2> */}
                            </div>
                        </div>
                    }

                    {mediaMatchesXs && <>
                        <Carousel fade pause="false" interval="5000" defaultActiveIndex={Math.round(Math.random() * 17)}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42].map(i => (
                                <Carousel.Item key={i}>
                                    <img
                                        className="d-block w-100 home-page-carousel-image"
                                        src={"assets/course-images/hole - " + i + ".png"}
                                        alt="First slide"
                                    />
                                    <Carousel.Caption style={{ top: '80px', bottom: 'unset' }}>
                                        <div className="main-motivator-shadow mb-5">Handicap Down!</div>
                                        <div className="main-subtitle mb-5">
                                            {/* <div>Manage your Score Cards</div>
                                            <div>Track your evolution</div> */}
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </>
                    }
                </div>

                <section className="d-flex flex-column flex-sm-row gap-sm-4 p-5">
                    <div className="message order-sm-1 mb-4">
                        <img
                            alt=""
                            src="/assets/course-images/hole - 1.jpeg"
                            width='100%'
                        />
                    </div>
                    <div className="message order-sm-2">
                        <div className="section-title">Live register your score cards</div>
                        <div>While your are playing, use your <Link to="/score-cards">score Cards</Link> to register your shots, putts, bunker shots and fairway.</div>
                    </div>
                </section>

                <section className="d-flex flex-column flex-sm-row gap-sm-4 p-5">
                    <div className="message order-2 order-sm-1">
                        <div className="section-title">Organize or participate in matches</div>
                        <div>You can create your own <Link to="/matches">matches</Link> and invite your friends or be invited to participate in other organizer's match.</div>
                    </div>
                    <div className="message order-1 order-sm-2 mb-4">
                        <img
                            alt=""
                            src="/assets/course-images/hole - 2.jpeg"
                            width='100%'
                        />
                    </div>
                </section>

                <section className="d-flex flex-column flex-sm-row gap-sm-4 p-5">
                    <div className="message mb-4">
                        <img
                            alt=""
                            src="/assets/course-images/hole - 3.jpeg"
                            width='100%'
                        />
                    </div>
                    <div className="message">
                        <div className="section-title">Plan your tee times</div>
                        <div>Create your score cards and see your game planification in a monthly, weekly or daily <Link to="/calendar">calendar</Link>.</div>
                    </div>
                </section>

                <section className="d-flex flex-column flex-sm-row gap-sm-4 p-5">
                    <div className="message order-2 order-sm-1">
                        <div className="section-title">Growing courses database</div>
                        <div>We are constantly adding <Link to="/courses">courses</Link> to our database, so you can register your score cards easily.</div>
                    </div>
                    <div className="message order-1 order-sm-2 mb-4">
                        <img
                            alt=""
                            src="/assets/course-images/hole - 4.jpeg"
                            width='100%'
                        />
                    </div>
                </section>
            </div >
        </>
    )
}